@charset "UTF-8";
/* CSS Document */

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;500;800&display=swap');

BODY {
	background-color: #ffffff;
	margin: 0px 0px 0px 0px;
}
#splash {
	position: relative;
	margin-top: 50px;
	width: 96%;
	margin-left: 2%;
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	min-height: 96vh;
}
#splashInner {
	background-color: #fff;
	width: 230px;
}

#splashInner h1,
#splashInner h2 {
	font-family: 'Fira Sans', sans-serif;
	font-size: 32px;
	color: #333;
	padding: 0px;
	margin: 0px;
	width: 90%;
	font-weight: 800;
}

#splashInner h2 {
	font-size: 18px;
	font-weight: normal;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 10px;
}

#splashInner ul {
	width: 90%;
	padding: 0px;
	margin: 0px;
	list-style: none;
	display: inline-block;
	margin-top: 50px;
}

#splashInner ul li {
	width: 100%;
}

#splashInner ul li + li {
	margin-top: 10px;
}

#gallery {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	background-color: #fff;
	margin-bottom: 150px;
	margin-left: 5%;
	width: 70%;
}

.itGallery {
	background-color: #fff;
	width: 48%;
	transition: all 500ms;
	padding-bottom: 25px;
}

.itGallery:hover {
	opacity: 0.7;
}

.itGallery .title {
	display: table;
	padding-top: 4px;
}

.itGallery .title A {
	font-family: 'Fira Sans', sans-serif;
	font-size: 20px;
	color: #333;
	text-decoration: none;
}

.itGallery .foto {
	width: 100%;
}

.itGallery .foto IMG {
	width: 100%;
	height: auto;
}

.itGallery .tags A,
#project .tags A {
	font-family: Verdana, Geneva, sans-serif;
	font-size: 11px;
	color: #000;
	text-decoration: none;
}

.itGallery .tags A:hover,
#project .tags A:hover {
	text-decoration: underline;
}

#gallery .filter {
	display: table;
	font-family: 'Goudy Bookletter 1911';
	font-size: 20px;
	color: #333;
	text-decoration: none;
	padding: 10px;
	background-color: #fff;
	margin-bottom: 10px;
}

A.section {
	font-family: 'Fira Sans', sans-serif;
	font-weight: 300;
	font-size: 20px;
	color: #666;
	text-decoration: none;
	position: relative;
	text-decoration: none;
	transition: all 500ms;
}

A.section::after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 1px;
	left: 50%;
	position: absolute;
	background: #333;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}

A.section:hover {
	color: #333;
}

A.section:hover::after {
	width: 100%;
	left: 0;
}

A.section.sectionOn {
	color: #a1a904;
}

A.section.sectionOn::after {
	background: #a1a904;
	width: 100%;
	left: 0;
}

#project {
	background-color: #fff;
	margin-bottom: 10px;
	margin-left: 5%;
	width: 70%;
}

#project.contacto {
	margin-bottom: 300px;
}

#project .title {
	display: table;
	/*font-family: 'Goudy Bookletter 1911';*/
	font-family: 'Fira Sans', sans-serif;
	font-size: 32px;
	color: #333;
	text-decoration: none;
	padding-top: 0px;
	padding-bottom: 10px;
}

#project .title.skills {
	font-size: 22px;
	color: #a1a904;
	padding-bottom: 3px;
	text-align: center;
	display: block;
	margin-top: 40px;
}

#project .title A,
#gallery .filter A {
	font-size: 16px;
	color: #666;
	text-decoration: none;
}

#project .title A:hover,
#gallery .filter A:hover {
	color: #333;
}

#project .attach {
	display: table;
	padding-bottom: 10px;
	width: 100%;
}

#project .attach IMG,
#project .attach video {
	width: 100%;
	height: auto;
}

#project .attach + .label {
	margin-top: 50px;
}

#project .attach + .attach {
	margin-top: 50px;
}

#project .profile {
	width: 200px;
	margin-bottom: 40px;
}

#project .profile img {
	width: 100%;
	height: auto;
	border-radius: 50%;
}

#project .desc {
	display: table;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 1.4;
	color: #000;
	padding-bottom: 30px;
}

#project .desc.desContacto {
	margin-top: 48px;
}

#project .desc.label {
	color: #a1a904;
	padding-bottom: 10px;
	padding-top: 30px;
}

#project .desc A {
	color: #a1a904;
}

#project .tags {
	display: flex;
	padding-bottom: 100px;
	justify-content: flex-start;
}

#project .tags a {
	padding: 6px 15px;
	border: 1px solid #000;
	border-radius: 17px;
	pointer-events: none;
}

#project .tags a + a {
	margin-left: 14px;
}

#project .groupIcon {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 90%;
	margin-left: 5%;
	padding: 0px;
	list-style: none;
}

#project .groupIcon li {
	height: 110px;
	min-width: 135px;
	width: 135px;
	margin-bottom: 1.5rem;
	border-radius: .25rem;
	padding-right: 1.5rem;
}

#project .groupIcon li + li {
	/*margin-left: 1.5rem;*/
}

#project .groupIcon li article {
	border-radius: .25rem;
	background-color: rgba(229, 231, 235, .7);
}

#project .groupIcon li.soft article {
	background-color: rgba(229, 231, 235, .3);
}

#project .groupIcon li article figure {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	height: 100%;
	margin: auto;
	padding-top: 14px;
}

#project .groupIcon li article figure img,
#project .groupIcon li article figure svg {
	height: 3rem;
	margin-left: auto;
	margin-right: auto;
	display: block;
	vertical-align: middle;
}

#project .groupIcon li article figure svg {
	width: 80%;
}

#project .groupIcon li article figure h3 {
	font-size: .875rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
}

#project .legend {
	width: 80%;
	max-width: 300px;
	margin: auto;
	padding: 0px;
	list-style: none;
	margin-top: 30px;
}

#project .legend .legendItem {
	display: flex;
	align-items: center;
	margin-top: 15px;
}

#project .legend .legendItem .quad {
	width: 30px;
	height: 30px;
	background-color: rgba(229, 231, 235, .3);
	border-radius: .25rem;
	margin-right: 15px;
}

#project .legend .legendItem .quad.solid {
	background-color: rgba(229, 231, 235, .7);
}

#promsite,
.promsiteResponsive {
	width: 130px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: absolute;
	bottom: 50px;
}

.promsiteResponsive {
	display: none;
	clear: both;
}

#promsite img {
	width: 100%;
	margin-bottom: -5px;
	height: auto;
}

#promsite a {
	font-size: 11px;
	text-align: right;
	margin-right: 7px;
}

@media screen and (max-width: 900px) {
	#project {
		top: 220px;
		left: 13px;
		width: 92%;
		max-height: none;
	}

	#project .desc {
		width: 90%;
	}

	#project .attach {
		width: 100%;
	}
	#project .attach IMG {
		width: 100%;
		height: auto;
	}
}

@media screen and (max-width: 768px) {
	#splash {
		flex-direction: column;
	}

	#splashInner {
		width: 100%;
	}

	#splashInner ul {
		width: 100%;
		margin-bottom: 30px;
	}

	#splashInner ul li {
		display: inline-block;
		width: 20%;
	}

	#splashInner ul li {
		display: inline-block;
		text-align: center;
		width: 32%;
	}

	#promsite {
		display: none;
	}

	#gallery,
	#project {
		width: 100%;
		margin-left: 0%;
	}

	.promsiteResponsive {
		display: flex;
	}
}

@media screen and (max-width: 700px) {
	#splashInner ul {
		width: 100%;
	}

	#splashInner ul li {
		display: inline-block;
		text-align: center;
		width: 32%;
	}

	.itGallery {
		width: 100%;
	}
}
